import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/layout';
import CTA from '../../components/button';

const Page = props => (
  <Layout>
    <div>
      <Row className="heading" middle="xs" center="xs">
        <Col xs={10}>
          <h2 className="section-heading scroll">Endure SVG Font</h2>
          <h3>A Dry Brush SVG Font</h3>
          <Row start="xs">
            <p>
              Endure is a new hand-painted brush font for designers looking to add a high quality and realistic feel to
              any project. Every glyph in Endure was painted by hand on actual papern and scanned in manually. The
              process was gruelling but worth the final result. I hope you enjoy, thanks for checking it out!
            </p>
            <p>Includes: 2 styles OpenType-SVG (textured) and 2 styles in traditional OTF/TTF formats.</p>
          </Row>
        </Col>
        <Col xs={10}>
          <Img fluid={props.data.intro.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.glyphs.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.collage.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.dreaming.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.compare.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.sauce.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.fitness.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.clear.childImageSharp.fluid} />
        </Col>
      </Row>
      <Row center="xs">
        <a href="/shop/noma">
          <h2>Check out my other font, NOMA.</h2>
        </a>
      </Row>
    </div>
  </Layout>
);

Page.displayName = 'Product Page';
export default Page;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    intro: file(relativePath: { eq: "intro.jpg" }) {
      ...fluidImage
    }
    collage: file(relativePath: { eq: "collage.jpg" }) {
      ...fluidImage
    }
    clear: file(relativePath: { eq: "clear-eyes.jpg" }) {
      ...fluidImage
    }
    fitness: file(relativePath: { eq: "fitness-first.jpg" }) {
      ...fluidImage
    }
    glyphs: file(relativePath: { eq: "glyph-overview.jpg" }) {
      ...fluidImage
    }
    dreaming: file(relativePath: { eq: "never-stop-dreaming.jpg" }) {
      ...fluidImage
    }
    compare: file(relativePath: { eq: "svg-vs-standard.jpg" }) {
      ...fluidImage
    }
    sauce: file(relativePath: { eq: "way-too-hot.jpg" }) {
      ...fluidImage
    }
  }
`;
